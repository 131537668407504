/* eslint-disable react/no-array-index-key, no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  AutocompleteSelectFilter,
  DateFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import {
  getDateCell,
  mapValueFromOptions,
} from "src/Components/DataTable/commonCells";
import ActionColumn from "src/Components/DataTable/actionColumn";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import __ from "src/utils/Translations";
import translateLabels from "src/utils/Translations/translateLabels";
import { API_TOTALREWARD_SERVICE } from "src/utils/Api";
import useQueryTotalRewardUsers from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardUsers";
import { isObject } from "lodash";
import ImportForm from "./form";

export default function ImportListing({ companyId }) {
  const { options: usersOptions } = useQueryTotalRewardUsers(companyId, 1);

  const [imported, setImported] = useState("");

  const [details, setDetails] = useState(null);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const fetchData = useCallback(
    async (filters, page, pageSize, sort) => {
      if (sort === null) {
        // eslint-disable-next-line no-param-reassign
        sort = { key: "created.at", value: "desc" };
      }
      const options = {
        params: { tenant: companyId },
        dateFilters: ["created.at"],
      };
      const { data: newData, count: newCount } = await getListingData(
        API_TOTALREWARD_SERVICE,
        `/omb/imports`,
        filters,
        page,
        pageSize,
        sort,
        options,
        mockData,
        true
      );
      if (newData) {
        setData(newData);
        setCount(newCount);
      }
    },
    [companyId]
  );

  return (
    <>
      <ImportForm
        companyId={companyId}
        imported={imported}
        setImported={setImported}
      />
      <DataTableControlled
        key={imported}
        id="PerkUsersImportsListing"
        fetchData={fetchData}
        data={data}
        count={count}
        filterable
        paramsInUrl={false}
        reloadDependencies={[imported]}
        shouldUpdateOnFiltersChange
        getTrProps={(state, rowInfo) => ({
          style: {
            backgroundColor:
              rowInfo.row.id === imported ? "#f6f483" : undefined,
          },
        })}
        columns={[
          {
            Header: __("ID"),
            accessor: "id",
            width: 150,
            Cell: BusinessIdColumn,
            Filter: UuidFilter,
          },
          {
            Header: __("Plik"),
            accessor: "originalFileName",
          },
          {
            Header: __("Data utworzenia"),
            accessor: "created.at",
            width: 150,
            Filter: DateFilter(),
            Cell: getDateCell("created.at", true),
          },
          {
            Header: __("Utworzony przez"),
            accessor: "created.by",
            Filter: AutocompleteSelectFilter(usersOptions),
            Cell: mapValueFromOptions(usersOptions, "created.by"),
          },
          {
            Header: __("Status"),
            accessor: "status",
            Filter: AutocompleteSelectFilter(
              translateLabels(importStatusOptions)
            ),
            Cell: mapValueFromOptions(
              translateLabels(importStatusOptions),
              "status"
            ),
          },
          {
            Header: __("Zaimportowane"),
            accessor: "result.success",
            width: 140,
            Cell: ({ row }) => (
              <>
                {row._original.result?.success}/
                {row._original.result?.countRows}
              </>
            ),
          },
          {
            Header: __("Niezaimportowane"),
            accessor: "result.failed",
            filterable: false,
            sortable: false,
            Cell: (cellData) => (
              <>
                {cellData.value?.length > 0 ? (
                  <ActionColumn
                    data={cellData.row._original}
                    buttons={[
                      {
                        id: "importDetails",
                        onClick: () => setDetails(cellData.row._original),
                        label: "Szczegóły",
                      },
                    ]}
                  />
                ) : (
                  <>-</>
                )}
              </>
            ),
          },
        ]}
        showPagination={false}
      />
      <Modal
        isOpen={!!details}
        toggle={() => setDetails(null)}
        unmountOnClose
        scrollable
        size="xl"
      >
        <ModalHeader toggle={() => setDetails(null)}>
          {__("Szczegóły importu")}
        </ModalHeader>
        <ModalBody>
          <div>
            {__("Plik")}: {details?.originalFileName}
          </div>
          <div>
            {__("Data utworzenia")}:{" "}
            {new Date(details?.created?.at).toLocaleString("pl-PL", {})}
          </div>
          <div>{__("Niezaimportowane")}:</div>
          <ul>
            {(details?.result?.failed || []).map((item, idx) => (
              <li key={idx}>
                {isObject(item)
                  ? `${__("Wiersz")} ${item.row} - ${item.reasons.join(",")}`
                  : item}
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setDetails(null)}>
            {__("Wróć")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mockData = [];

const importStatusOptions = [
  { value: "finished", label: "Zakończony" },
  { value: "started", label: "Rozpoczęty" },
  { value: "new", label: "Nowy" },
];

ImportListing.propTypes = {
  companyId: PropTypes.string.isRequired,
};
