/* eslint react/jsx-props-no-spreading: off */
/* eslint no-case-declarations: off */
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import InputMask from "react-input-mask";
import DOMPurify from "dompurify";

import { isNumber } from "recharts/lib/util/DataUtils";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import DatePicker from "src/Components/FormElements/DatePicker";
import DateRange from "src/Components/FormElements/DateRange";
import NumberRange from "src/Components/FormElements/NumberRange";
import Tooltip from "src/Components/Tooltips/DefaultTooltip";
import DateTimePicker from "src/Components/FormElements/DateTimePicker";
import __ from "src/utils/Translations";
import ValueFormatter from "src/utils/ValueFormatter";
import IPv4 from "src/Components/FormElements/IPv4";
import Multiselect from "src/Components/FormElements/Multiselect";
import MultiselectNew from "src/Components/FormElements/MultiselectNew";
// eslint-disable-next-line import/no-cycle
import DynamicTranslationTrigger from "src/Components/DynamicTranslation/DynamicTranslationTrigger";
import Wysiwyg from "src/Components/FormElements/Wysiwyg";
import {
  Autocomplete,
  AsyncAutocomplete,
} from "src/Components/FormElements/Autocomplete/index";
import InputSwitcher from "src/Components/FormElements/InputSwitcher";
import Checkboxes from "src/Components/FormElements/Checkboxes";
import hashInputId from "src/utils/jsHelpers/hashInputId";
import PasswordInput from "src/Components/FormElements/PasswordInput";
import Legend from "src/Components/FormElements/Legend";
import MonthPicker from "src/Components/FormElements/MonthPicker";
import FileInput from "src/Components/FormElements/FileInput";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";
import PeriodicModeForm from "src/Components/PeriodicModeForm/periodicModeForm";
import MultiInput from "src/Components/FormElements/MultiInput";
// eslint-disable-next-line import/no-cycle
import SelectCreator from "src/Components/FormElements/SelectCreator";
import TagInput from "src/Components/FormElements/TagInput";
import InfiniteInputList from "src/Components/FormElements/InfiniteInputList";
import BankListSelector, { bankListInputId } from "./BankListSelector";
import { defaultValidation } from "./utils";
import ValidationMessage from "./ValidationMessage";

export default function FormElement({
  id,
  type,
  validation,
  data1,
  data2,
  data3,
  placeholder: placeholderProps,
  props: restProps = {},
  label: labelPops,
  onChange,
  value,
  translatedValue,
  options = [],
  tooltip,
  tooltipPosition,
  suffix,
  prefix,
  valueFormatter,
  validateField,
  errorMessage,
  translatable,
  fetchOptions,
  isCheckbox,
  inputSwitcher,
  className,
  showTimeSelect,
  minDate,
  maxDate,
  formatBeforeDisplay,
  uploadService,
  legend,
  allowDirtyHTML,
  companyId,
  setRadioFlag,
  radioFlag,
  startDateLabel,
  endDateLabel,
  invalidFields,
  addEmptyValue,
  isTrimmed,
  isTrimmedOnBlur,
  confirmChange,
  isContentTranslatable,
  mask = null,
  maskChar = null,
  chooseFilePopup,
  disabledByExclusiveFields,
  disabledInDefault,
  selectSize,
  configSchema,
}) {
  let updateFunction;
  let tooltipComponent;
  if (tooltip) {
    tooltipComponent = (
      <Tooltip
        id={tooltip.helpingId || `form_tooltip_${id}`}
        type={tooltip.type}
        content={tooltip.content}
        placement={tooltip.placement}
      />
    );
  }
  const placeholder = placeholderProps ? __(placeholderProps) : null;
  let label = null;
  if (labelPops) {
    const isRequired = validation && validation.find((el) => el === "required");
    label = __(labelPops);
    if (isRequired) {
      label = (
        <span key={label}>
          {label} <span className="text-danger">*</span>
        </span>
      );
    }
  }
  const isInvalid = Boolean(errorMessage);
  const {
    format,
    inline,
    columns,
    height,
    withLines,
    disabled,
    previewToggle,
    buttons,
  } = restProps;

  let translatorTrigger;
  if (translatable && translatable.show !== false) {
    translatorTrigger = (
      <DynamicTranslationTrigger
        scope={translatable.scope}
        code={translatable.code}
        isCms={translatable.isCms}
        value={value}
        translatedValue={translatedValue}
        isTitle={translatable.isTitle || false}
        label={__(labelPops)}
        type={type}
        uploadService={uploadService}
        isNotDefault={translatable.isNotDefault}
        isLocked={translatable.isLocked}
        lockReason={translatable.lockReason}
        onTranslatorSubmit={translatable.onTranslatorSubmit}
        allowDirtyHtml={translatable.allowDirtyHtml}
      />
    );
  }
  let inputDisabled = disabled;

  let inputSwitcherComponent = null;
  let inputSwitcherUnder = false;
  if (inputSwitcher && !inputSwitcher.perOption) {
    const onChangeInputSwitcher = (isChecked) => {
      if (inputSwitcher.onChange) {
        inputSwitcher.onChange(id, isChecked);
        if (isChecked) {
          validateField(id, value, []);
        }
      }
    };

    const checked = !isEmptyValue(inputSwitcher.switcherValue)
      ? inputSwitcher.switcherValue
      : Boolean(inputSwitcher && inputSwitcher.checkedByDefault);
    inputSwitcherComponent = (
      <InputSwitcher
        onChange={onChangeInputSwitcher}
        id={id}
        checked={checked}
        label={inputSwitcher.label}
        dataT1Custom={inputSwitcher.dataT1Custom}
        disabled={
          inputSwitcher.disabled ||
          disabledByExclusiveFields ||
          disabledInDefault
        }
      />
    );
    inputSwitcherUnder = inputSwitcher.under;
    inputDisabled =
      disabled ||
      disabledByExclusiveFields ||
      disabledInDefault ||
      (inputSwitcher.disableIfChecked && checked) ||
      (inputSwitcher.disableIfNotChecked && !checked);
  }

  const isReadyForValidation =
    restProps?.isFieldValid === undefined ? true : restProps?.isFieldValid;

  let inputGroup;
  switch (type) {
    case "text":
    case "textarea":
    case "time":
    case "number":
    case "email":
    case "integer":
      const inputValidation = validation ? [...validation] : [];
      if (["text", "time", "number", "email", "integer"].includes(type)) {
        inputValidation.push(defaultValidation);
      }
      updateFunction = (e) => {
        let { value: newValue } = e.target;
        if (newValue) {
          const trimmedValue =
            newValue.length > 1 && !isTrimmed ? newValue : newValue.trimStart();
          newValue = allowDirtyHTML
            ? trimmedValue
            : DOMPurify.sanitize(trimmedValue);
        }
        if (type === "number") {
          if (newValue === "") {
            newValue = null;
          } else {
            newValue = +newValue;
            if (isNumber(restProps.min) && newValue < restProps.min) {
              newValue = restProps.min;
            } else if (isNumber(restProps.max) && newValue > restProps.max) {
              newValue = restProps.max;
            }
          }
        }
        if (type === "integer") {
          if (newValue === "") {
            newValue = null;
          } else {
            newValue = ValueFormatter("integer", newValue);
          }
        }

        if (valueFormatter) {
          newValue = ValueFormatter(valueFormatter, newValue);
        }
        if (isInvalid && inputValidation) {
          validateField(id, newValue, inputValidation);
        }

        onChange(id, newValue);
      };
      const displayValue =
        valueFormatter && value !== null && formatBeforeDisplay
          ? ValueFormatter(valueFormatter, value)
          : value;

      if (inputDisabled) {
        validateField(id, value, inputValidation);
      }

      const input = !mask ? (
        <Input
          invalid={isInvalid}
          type={type}
          name={hashInputId(id)}
          onBlur={(e) => {
            if (!isTrimmed) onChange(id, e.target.value.trimStart());
            if (isTrimmedOnBlur)
              onChange(id, e.target.value.replace(/\s+/g, " ").trim());
            validateField(id, e.target.value, inputValidation);
          }}
          data-t1={data1 || id}
          data-t3={data3}
          id={hashInputId(id)}
          placeholder={placeholder}
          value={displayValue || displayValue === 0 ? displayValue : ""}
          onChange={updateFunction}
          disabled={inputDisabled}
          className={className}
          autoComplete="chrome-off"
          {...restProps}
        />
      ) : (
        <InputMask
          invalid={isInvalid}
          type={type}
          name={hashInputId(id)}
          onBlur={(e) => {
            if (!isTrimmed) onChange(id, e.target.value.trimStart());
            validateField(id, e.target.value, inputValidation);
          }}
          data-t1={data1 || id}
          id={hashInputId(id)}
          placeholder={placeholder}
          value={displayValue || displayValue === 0 ? displayValue : ""}
          onChange={updateFunction}
          disabled={inputDisabled}
          className={`form-control ${
            isInvalid ? "is-invalid " : ""
          } ${className}`}
          autoComplete="chrome-off"
          mask={mask}
          maskChar={maskChar}
          {...restProps}
        />
      );

      inputGroup = (
        <InputGroup>
          {prefix ? (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{prefix}</InputGroupText>
            </InputGroupAddon>
          ) : null}
          {input}
          {suffix ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{suffix}</InputGroupText>
            </InputGroupAddon>
          ) : null}
          {tooltipComponent && tooltipPosition === "input" ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{tooltipComponent}</InputGroupText>
            </InputGroupAddon>
          ) : null}
          <ValidationMessage message={errorMessage} />
        </InputGroup>
      );

      const isBankList = id === bankListInputId;
      if (isBankList && !companyId) return null;

      return (
        <FormGroup>
          {label ||
          (tooltipComponent && tooltipPosition === "label") ||
          translatorTrigger ? (
            <Label data-t1={`${id}Label`} for={id}>
              {label}
              &nbsp;
              {translatorTrigger || ""}
              {tooltipComponent && tooltipPosition === "label" ? (
                <> {tooltipComponent}</>
              ) : null}
            </Label>
          ) : null}
          {inputSwitcherComponent ? (
            <Row>
              <Col sm={inputSwitcherUnder ? 12 : 9}>
                {isBankList ? (
                  <BankListSelector
                    onChange={onChange}
                    companyId={companyId}
                    input={inputGroup}
                    value={value}
                  />
                ) : (
                  inputGroup
                )}
              </Col>
              <Col sm={inputSwitcherUnder ? 12 : 3}>
                {inputSwitcherComponent}
              </Col>
            </Row>
          ) : (
            inputGroup
          )}
        </FormGroup>
      );
    case "password":
      return (
        <PasswordInput
          id={id}
          className={className}
          label={label}
          value={value}
          errorMessage={errorMessage}
          validation={validation}
          validateField={validateField}
          disabled={inputDisabled}
          onChange={onChange}
          tooltip={tooltipComponent}
          previewToggle={previewToggle}
        />
      );
    case "boolean":
      updateFunction = (isOn) => {
        onChange(id, isOn);
      };
      return !isCheckbox ? (
        <FormGroup>
          <ToggleSwitch
            id={id}
            label={label}
            checked={Boolean(value)}
            handleChange={updateFunction}
            disabled={inputDisabled}
            tooltip={tooltipComponent}
            afterLabel={inputSwitcherComponent}
            className={className}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <Row>
            <CustomInput
              data-t1={data1 || id}
              data-t2={data2}
              data-t3={data3}
              inline={inline}
              checked={Boolean(value)}
              type="checkbox"
              id={hashInputId(id)}
              disabled={inputDisabled}
              onChange={(e) => updateFunction(e.target.checked)}
              value={Boolean(value)}
              name={id}
              className={"ml-3" || className}
              label={
                <span data-t1={data1 || id} data-t2={data2} data-t3={data3}>
                  {label}
                </span>
              }
            />
            {tooltipComponent ? (
              <div className="ml-1">{tooltipComponent}</div>
            ) : null}
          </Row>
        </FormGroup>
      );
    case "select":
      updateFunction = (e) => {
        let { value: newValue } = e.target;

        if (newValue === "") {
          newValue = null;
        }
        if (valueFormatter) {
          newValue = ValueFormatter(valueFormatter, newValue);
        }
        validateField(id, e.target.value, validation);
        onChange(id, newValue);
      };
      const newOptions = [...options];
      if (addEmptyValue) {
        newOptions.unshift({
          value: "",
          label: `--- ${__("Nie wybrano")} ---`,
        });
      }

      inputGroup = (
        <InputGroup>
          <Input
            data-t1={id}
            data-t2={
              newOptions.find((opt) => opt.value === (value ?? ""))?.label
            }
            data-t3={value || "nullOption"}
            type="select"
            size={selectSize}
            name={id}
            id={id}
            disabled={inputDisabled}
            className={`${className}${
              errorMessage ? " is-invalid-select" : ""
            }`}
            value={value || ""}
            onChange={updateFunction}
          >
            {newOptions.map(({ value: optionValue, label: optionLabel }) => (
              <option
                key={optionValue}
                value={optionValue}
                data-t1={id}
                data-t2={optionLabel}
                data-t3={
                  optionValue ? `${id}_${optionValue}` : `${id}_nullOption`
                }
              >
                {isContentTranslatable
                  ? __(optionLabel) || __(optionValue)
                  : optionLabel || optionValue}
              </option>
            ))}
          </Input>
          <ValidationMessage message={errorMessage} />
          {suffix ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{suffix}</InputGroupText>
            </InputGroupAddon>
          ) : null}
          {tooltipComponent && tooltipPosition === "input" ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{tooltipComponent}</InputGroupText>
            </InputGroupAddon>
          ) : null}
        </InputGroup>
      );
      return (
        <FormGroup>
          <Label data-t1={`${id}Label`} for={id}>
            {label}
            {tooltipComponent && tooltipPosition === "label" ? (
              <> {tooltipComponent}</>
            ) : null}
          </Label>
          {inputSwitcherComponent ? (
            <Row>
              <Col sm={9}>{inputGroup}</Col>
              <Col sm={3}>{inputSwitcherComponent}</Col>
            </Row>
          ) : (
            inputGroup
          )}
        </FormGroup>
      );
    case "radio":
      updateFunction = (e) => {
        validateField(id, e.target.dataset.inputValue, validation);
        if (typeof setRadioFlag === "function") {
          setRadioFlag(e.target.dataset.inputValue);
        }
        onChange(id, e.target.dataset.inputValue);
      };
      return (
        <FormGroup className="input-group-omb">
          {label ? (
            <Label data-t1={`${id}Label`} for={id}>
              {label}
              &nbsp;
              {tooltipComponent}
              {inputSwitcherComponent ? (
                <span data-t1={`${id}LabelSwitcher`} className="ml-3">
                  {inputSwitcherComponent}
                </span>
              ) : null}
            </Label>
          ) : null}
          <div data-t1={id}>
            {options.map(
              ({
                value: optionValue,
                label: optionLabel,
                data1: datat1 = `${id}${
                  String(optionValue).charAt(0).toUpperCase() +
                  String(optionValue).slice(1)
                }`,
                isNotDefaultDataT1,
                tooltip: optionTooltip,
                disabled: optionDisabled,
              }) => {
                let optionLabelComponent = (
                  <span data-t1={datat1}>{optionLabel}</span>
                );
                if (optionTooltip) {
                  const optionTooltipComponent = (
                    <Tooltip
                      id={`form_tooltip_${optionValue}`}
                      content={optionTooltip.content ?? optionTooltip}
                    />
                  );
                  optionLabelComponent = (
                    <span
                      data-t1={isNotDefaultDataT1 ? datat1 : `${id}OptionLabel`}
                    >
                      {optionLabel} {optionTooltipComponent}
                    </span>
                  );
                }
                // @TODO option
                return (
                  <CustomInput
                    invalid={isInvalid}
                    key={optionValue}
                    type="radio"
                    id={hashInputId(optionValue)}
                    onChange={updateFunction}
                    value={Boolean(value && value === String(optionValue))}
                    checked={Boolean(value && value === String(optionValue))}
                    name={id}
                    disabled={optionDisabled || inputDisabled}
                    label={optionLabelComponent}
                    data-t1={datat1}
                    data-input-value={optionValue}
                  />
                );
              }
            )}
          </div>
          <ValidationMessage message={errorMessage} />
        </FormGroup>
      );
    case "checkbox":
      return (
        <FormGroup>
          <Checkboxes
            options={options}
            disabled={inputDisabled}
            afterLabel={inputSwitcherComponent}
            label={label}
            tooltip={tooltipComponent}
            columns={columns}
            id={id}
            buttons={buttons}
            validation={validation}
            value={value}
            errorMessage={errorMessage}
            onChange={onChange}
            valueFormatter={valueFormatter}
            validateField={validateField}
            inline={inline}
            optionSwitcher={
              inputSwitcher && inputSwitcher.perOption ? inputSwitcher : null
            }
            data1={data1}
          />
        </FormGroup>
      );
    case "date":
      updateFunction = (date) => {
        onChange(id, date);
      };
      return (
        <FormGroup>
          <DatePicker
            label={label}
            tooltip={tooltipComponent}
            tooltipPosition={tooltipPosition}
            value={value}
            inputSwitcher={inputSwitcherComponent}
            disabled={inputDisabled}
            onChange={updateFunction}
            id={id}
            errorMessage={errorMessage}
            validation={validation}
            validateField={validateField}
            format={format}
            minDate={minDate}
            showTimeSelect={showTimeSelect}
            confirmChange={confirmChange}
          />
        </FormGroup>
      );
    case "month":
      updateFunction = (date) => {
        onChange(id, date);
      };
      return (
        <FormGroup>
          <MonthPicker
            label={label}
            tooltip={tooltipComponent}
            tooltipPosition={tooltipPosition}
            value={value}
            inputSwitcher={inputSwitcherComponent}
            disabled={inputDisabled}
            onChange={updateFunction}
            id={id}
            errorMessage={errorMessage}
            validation={validation}
            validateField={validateField}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            showTimeSelect={showTimeSelect}
          />
        </FormGroup>
      );
    case "dateRange":
      updateFunction = (date) => {
        onChange(id, date);
      };
      return (
        <FormGroup>
          <DateRange
            label={label}
            value={value}
            disabled={inputDisabled}
            afterLabel={inputSwitcherComponent}
            onChange={updateFunction}
            id={id}
            {...restProps}
            format={format}
            validation={validation}
            errorMessage={errorMessage}
            validateField={validateField}
            data1={data1}
            radioFlag={radioFlag}
            setRadioFlag={setRadioFlag}
            startDateLabel={startDateLabel}
            endDateLabel={endDateLabel}
            tooltipComponent={tooltipComponent}
          />
        </FormGroup>
      );
    case "dateRangeExtend":
      updateFunction = (field, inputValue) => {
        const { fromDay, toDay } = value;
        const newValue = { ...value };
        const hasNoValues = !fromDay && !toDay;

        const isFromDayOtherThanToDay =
          !!fromDay && !!toDay && fromDay !== toDay;
        const isFromDayGreaterThanToDay = inputValue < fromDay;

        switch (true) {
          case field === "periodicallyDays" &&
            (hasNoValues ||
              isFromDayOtherThanToDay ||
              isFromDayGreaterThanToDay):
            newValue.toDay = Number(inputValue);
            newValue.fromDay = Number(inputValue);
            break;
          case field === "periodicallyDays" && fromDay === toDay:
            newValue.toDay = Number(inputValue);
            break;
          case field === "periodicallyMonths":
            newValue.months = inputValue;
            break;
          default:
            break;
        }

        onChange(id, newValue);
      };

      const updateStartDate = (inputDate) => {
        const newValue = { ...value };
        newValue.startDate = inputDate;
        onChange(id, newValue);
      };

      const fieldErrors = invalidFields[id]
        ? JSON.parse(invalidFields[id])
        : {};

      return (
        <FormGroup>
          <DatePicker
            label="Data rozpoczęcia:"
            value={value.startDate || null}
            inputSwitcher={inputSwitcherComponent}
            validateField={validateField}
            disabled={inputDisabled}
            onChange={updateStartDate}
            id={`${id}StartDate`}
            validation={validation}
            format={format}
            minDate={minDate}
            showTimeSelect={showTimeSelect}
            errorMessage={fieldErrors.startDate}
          />
          <PeriodicModeForm
            validateField={validateField}
            onChange={updateFunction}
            isDateRangeExtend
            dayErrorMessage={fieldErrors.fromDay || fieldErrors.toDay}
            monthErrorMessage={fieldErrors.months}
            months={value.months.map((month) => String(month).padStart(2, "0"))}
            days={{ fromDay: value.fromDay, toDay: value.toDay }}
          />
        </FormGroup>
      );
    case "numberRange":
      updateFunction = (date) => {
        onChange(id, date);
      };
      return (
        <FormGroup>
          <NumberRange
            label={label}
            value={value}
            onChange={updateFunction}
            disabled={inputDisabled}
            afterLabel={inputSwitcherComponent}
            id={id}
            {...restProps}
            errorMessage={errorMessage}
            validateField={validateField}
            validation={validation}
          />
        </FormGroup>
      );
    case "file":
      updateFunction = (e) => {
        validateField(id, e.target.files, validation);
        onChange(id, e.target.files);
      };
      inputGroup = (
        <InputGroup
          className={errorMessage ? "error-message-color" : undefined}
        >
          <FileInput
            id={id}
            onChange={updateFunction}
            value={value}
            disabled={disabled}
            invalid={isInvalid}
            chooseFilePopup={chooseFilePopup}
          />
          {tooltipComponent && tooltipPosition === "input" ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{tooltipComponent}</InputGroupText>
            </InputGroupAddon>
          ) : null}
          <ValidationMessage message={errorMessage} />
        </InputGroup>
      );
      return (
        <FormGroup>
          <Label data-t1={`${id}Label`} for={id}>
            {label}
            {tooltipComponent && tooltipPosition === "label" ? (
              <> {tooltipComponent}</>
            ) : null}
          </Label>
          {inputSwitcherComponent ? (
            <Row>
              <Col sm={9}>{inputGroup}</Col>
              <Col sm={3} data-t1={`${id}InputSwitcher`}>
                {inputSwitcherComponent}
              </Col>
            </Row>
          ) : (
            inputGroup
          )}
        </FormGroup>
      );
    case "staticValue":
      return (
        <FormGroup>
          <Label data-t1={`${id}Label`} for={id}>
            {label}
            &nbsp;
            {tooltipComponent}
          </Label>
          <div>{value !== undefined ? value : "b/d"}</div>
          <ValidationMessage message={errorMessage} />
        </FormGroup>
      );
    case "wysiwyg": {
      return (
        <FormGroup>
          <Wysiwyg
            id={id}
            label={label}
            translatorTrigger={translatorTrigger}
            value={value}
            height={height}
            errorMessage={errorMessage}
            validation={validation}
            validateField={validateField}
            disabled={inputDisabled}
            onChange={onChange}
            belowLabelComponent={inputSwitcherComponent}
            tooltip={tooltipComponent}
            uploadService={uploadService}
            allowDirtyHTML={allowDirtyHTML}
          />
        </FormGroup>
      );
    }

    case "infinite-input-list": {
      return (
        <FormGroup>
          <InfiniteInputList
            id={id}
            label={label}
            value={value}
            height={height}
            errorMessage={errorMessage}
            validation={validation}
            validateField={validateField}
            disabled={inputDisabled}
            onChange={onChange}
            afterLabel={inputSwitcherComponent}
            tooltip={tooltipComponent}
            uploadService={uploadService}
            allowDirtyHTML={allowDirtyHTML}
            translatable={translatable}
            translatedValue={translatedValue}
            configSchema={configSchema}
          />
        </FormGroup>
      );
    }

    case "autocomplete":
    case "autocompleteMultiselect":
      const isMultiselect = type === "autocompleteMultiselect";
      if (isMultiselect) {
        updateFunction = (selectedOptions) => {
          onChange(
            id,
            selectedOptions ? selectedOptions.map((el) => el.value) : []
          );
        };
      } else {
        updateFunction = (option) => {
          onChange(id, option ? option.value : null);
        };
      }
      return (
        <FormGroup>
          <Autocomplete
            id={id}
            label={label}
            isMultiselect={isMultiselect}
            errorMessage={isReadyForValidation ? errorMessage : ""}
            validation={validation}
            validateField={validateField}
            inputSwitcher={inputSwitcherComponent}
            disabled={inputDisabled}
            onChange={updateFunction}
            options={options}
            tooltip={tooltipComponent}
            value={value}
            {...restProps}
          />
        </FormGroup>
      );
    case "asyncAutocomplete":
    case "asyncAutocompleteMultiselect":
      const isAsyncMultiselect = type === "asyncAutocompleteMultiselect";
      if (isAsyncMultiselect) {
        updateFunction = (selectedOptions, isRemoveAction) => {
          onChange(
            id,
            selectedOptions ? selectedOptions.map((el) => el.value) : [],
            isRemoveAction
          );
        };
      } else {
        updateFunction = (option, isRemoveAction) => {
          onChange(id, option ? option.value : null, isRemoveAction);
        };
      }
      return (
        <FormGroup>
          <AsyncAutocomplete
            id={id}
            label={label}
            isMultiselect={isAsyncMultiselect}
            errorMessage={isReadyForValidation ? errorMessage : ""}
            fetchOptions={fetchOptions}
            inputSwitcher={inputSwitcherComponent}
            disabled={inputDisabled}
            validation={validation}
            validateField={validateField}
            onChange={updateFunction}
            tooltip={tooltipComponent}
            value={value}
          />
        </FormGroup>
      );
    case "datetime":
      updateFunction = ({ date, time }) => {
        onChange(id, {
          date,
          time,
        });
      };
      return (
        <FormGroup>
          <DateTimePicker
            id={id}
            label={label}
            tooltip={tooltipComponent}
            value={value}
            disabled={inputDisabled}
            afterLabel={inputSwitcherComponent}
            onChange={updateFunction}
            errorMessage={errorMessage}
            validateField={validateField}
            validation={validation}
          />
        </FormGroup>
      );
    case "datetimeRange":
      // TODO
      return null;
    case "title":
      const title = <CardTitle>{label}</CardTitle>;
      return withLines ? (
        <>
          <hr />
          {title}
          <hr />
        </>
      ) : (
        title
      );
    case "hr":
      return <hr />;
    case "button":
      return (
        <>
          <Button data-t1={id} onClick={onChange} {...restProps}>
            {label}
          </Button>
          {tooltipComponent}
        </>
      );
    case "ipv4":
      updateFunction = (e) => {
        let { value: newValue } = e.target;
        // eslint-disable-next-line no-shadow
        newValue = ValueFormatter("ipv4", newValue);
        onChange(id, newValue);
      };
      return (
        <FormGroup>
          <IPv4
            id={id}
            label={label}
            tooltip={tooltipComponent}
            tooltipPosition={tooltipPosition}
            value={value}
            onChange={updateFunction}
            inputSwitcher={inputSwitcherComponent}
            disabled={inputDisabled}
            errorMessage={errorMessage}
            validateField={validateField}
            validation={validation}
            placeholder="000.000.000.000"
          />
        </FormGroup>
      );
    case "multiselect":
      updateFunction = (e) => {
        const { options: multiselectOptions } = e.target;
        const newValue = [];
        multiselectOptions.forEach((option) => {
          if (option.selected) {
            newValue.push(option.value);
          }
        });
        onChange(id, newValue);
      };

      return (
        <Multiselect
          id={id}
          label={label}
          tooltip={tooltipComponent}
          value={value}
          onChange={updateFunction}
          afterLabel={inputSwitcherComponent}
          errorMessage={errorMessage}
          validateField={validateField}
          validation={validation}
          options={options}
          invalid={isInvalid}
          disabled={inputDisabled}
        />
      );
    case "multiselectNew":
      updateFunction = (newValue) => {
        onChange(id, newValue);
      };

      return (
        <MultiselectNew
          id={id}
          label={label}
          tooltip={tooltipComponent}
          value={value}
          onChange={updateFunction}
          afterLabel={inputSwitcherComponent}
          errorMessage={errorMessage}
          validateField={validateField}
          validation={validation}
          options={options}
          invalid={isInvalid}
          disabled={inputDisabled}
        />
      );
    case "legend":
      return (
        <Legend
          id={id}
          title={label}
          tooltip={tooltipComponent}
          legend={legend}
        />
      );
    case "multiInput":
      return (
        <MultiInput
          id={id}
          label={label}
          tooltip={tooltipComponent}
          value={value}
          onChange={(values) => onChange(id, values)}
          afterLabel={inputSwitcherComponent}
          errorMessage={errorMessage}
          invalid={isInvalid}
        />
      );
    case "tagInput":
      const { inputProps } = restProps;
      return (
        <TagInput
          id={id}
          label={label}
          tooltip={tooltipComponent}
          value={value}
          onChange={(values) => onChange(id, values)}
          invalid={isInvalid}
          inputProps={inputProps}
          {...restProps}
        />
      );
    case "selectCreate":
      return (
        <SelectCreator
          defaultValue={value || [""]}
          id={id}
          onChange={(values) => onChange(id, values)}
          errorMessage={errorMessage}
        />
      );
    default:
      return null;
  }
}

FormElement.propTypes = {
  data1: PropTypes.string,
  data2: PropTypes.string,
  data3: PropTypes.string,
  dataOldSk: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  allowDirtyHTML: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          isDisabled: PropTypes.bool,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        })
      ),
      className: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      tooltip: PropTypes.oneOfType([
        PropTypes.shape({
          content: PropTypes.node,
          type: PropTypes.string,
        }),
        PropTypes.node,
      ]),
    })
  ),
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  formatBeforeDisplay: PropTypes.bool,
  props: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
    columns: PropTypes.number,
    buttons: PropTypes.number,
    withLines: PropTypes.bool,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    isFieldValid: PropTypes.bool,
    disabledFrom: PropTypes.bool,
    disabledTo: PropTypes.bool,
    minDateFrom: PropTypes.instanceOf(Date),
    minDateTo: PropTypes.instanceOf(Date),
    previewToggle: PropTypes.bool,
    rows: PropTypes.number,
  }),
  suffix: PropTypes.string,
  tooltip: PropTypes.shape({
    content: PropTypes.node,
    type: PropTypes.string,
    placement: PropTypes.string,
    helpingId: PropTypes.string,
  }),
  tooltipPosition: PropTypes.oneOf(["label", "input"]),
  translatable: PropTypes.shape({
    show: PropTypes.bool,
    isCms: PropTypes.bool,
    code: PropTypes.string,
    isTitle: PropTypes.bool,
    scope: PropTypes.string,
    isNotDefault: PropTypes.bool,
    isLocked: PropTypes.bool,
    lockReason: PropTypes.string,
    allowDirtyHtml: PropTypes.bool,
    onTranslatorSubmit: PropTypes.func,
  }),
  displayCondition: PropTypes.bool, // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  valueFormatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  uploadService: PropTypes.string,
  validateField: PropTypes.func,
  fetchOptions: PropTypes.func,
  isCheckbox: PropTypes.bool,
  inputSwitcher: PropTypes.shape({
    onChange: PropTypes.func,
    label: PropTypes.string,
    checkedByDefault: PropTypes.bool,
    switcherValue: PropTypes.bool,
    disableIfChecked: PropTypes.bool,
    disableIfNotChecked: PropTypes.bool,
    perOption: PropTypes.bool,
    under: PropTypes.bool,
    dataT1Custom: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  companyId: PropTypes.string,
  radioFlag: PropTypes.string,
  setRadioFlag: PropTypes.func,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  invalidFields: PropTypes.shape({}).isRequired,
  addEmptyValue: PropTypes.bool,
  translatedValue: PropTypes.string,
  isTrimmed: PropTypes.bool,
  isTrimmedOnBlur: PropTypes.bool,
  confirmChange: PropTypes.func,
  isContentTranslatable: PropTypes.bool,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  chooseFilePopup: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string,
  }),
  disabledByExclusiveFields: PropTypes.bool.isRequired,
  selectSize: PropTypes.string,
  disabledInDefault: PropTypes.bool,
  configSchema: PropTypes.arrayOf(PropTypes.shape({})),
};

FormElement.defaultProps = {
  dataOldSk: "",
  allowDirtyHTML: false,
  label: "",
  id: null,
  type: "text",
  options: [],
  placeholder: "",
  prefix: "",
  legend: [],
  props: {},
  suffix: "",
  tooltip: null,
  tooltipPosition: "label",
  displayCondition: false,
  formatBeforeDisplay: false,
  validation: [],
  valueFormatter: null,
  value: null,
  translatedValue: "",
  errorMessage: null,
  validateField: null,
  translatable: null,
  uploadService: null,
  isCheckbox: null,
  fetchOptions: null,
  inputSwitcher: null,
  className: "",
  showTimeSelect: false,
  minDate: undefined,
  maxDate: undefined,
  data1: "",
  data2: "",
  data3: "",
  companyId: "",
  radioFlag: null,
  setRadioFlag: null,
  startDateLabel: undefined,
  endDateLabel: undefined,
  addEmptyValue: true,
  isTrimmed: true,
  isTrimmedOnBlur: false,
  confirmChange: null,
  isContentTranslatable: true,
  mask: null,
  maskChar: null,
  chooseFilePopup: null,
  selectSize: null,
  disabledInDefault: false,
  configSchema: [],
};
